import React from 'react'
import TeamListing from '../components/team/TeamListing';

function Team() {
  return (
    <>
    
    <div  className="mt70 sm-mt0">

        <section style={{backgroundColor:'#202124'}}>
            <div className="text-center">
                <h1>Team</h1>
            </div>
        </section>

        <section className="no-top no-bottom">
          <div className="container-fluid">

                <TeamListing />

          </div>
        </section>

    </div>    
    </>
  )
}
export default Team;
