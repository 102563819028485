import React, { useEffect, useState } from 'react'
import { Carousel } from 'react-bootstrap';
import { Client } from '../../api/Client';
import { Endpoint } from '../../Events/Endpoint';
import { isEmptyArray } from '../../Utility/Utility';
import courseBackground from './../../assets/images/background/bg-42.jpg';

function OfflineCourses() {
  const [coursesData, setCourses] = useState([]);
  const [coursesDetailsData, setCoursesDetails] = useState([]);
   

  useEffect(() => {
    getCourse();  
  }, []); 


  function getCourse() {
    const url = Endpoint.COURSE_FEATURED;;
    Client.getWithLoader(
      url,
      (response) => {
        console.log("response course", response.data.data);
        if (!isEmptyArray(response.data)) {
          if (response.data.status) {
            /* setCourses(response.data.data); */
            getAarray(response.data.data);
             
          }
        }
      },
      (error) => {
        setCourses([]);
      }
    );
  }

  function getAarray(arrayData){

    /* console.log("arrayData ", arrayData); */

    const perChunk = 4;  

    const inputArray = arrayData;
    
    const result = inputArray.reduce((resultArray, item, index) => { 
      const chunkIndex = Math.floor(index/perChunk)
    
      if(!resultArray[chunkIndex]) {
        resultArray[chunkIndex] = [] 
      }
    
      resultArray[chunkIndex].push(item)

      console.log("resultArray = ",resultArray); 

      setCourses(resultArray);
    
      return resultArray
    }, []);

  }
 

  return (
    <>
    
    
    <section
        id="section-services"
        className="de_light"
        style={{
          backgroundColor: "rgb(255, 255, 255)",
          backgroundSize: "cover",
        }}
      >
        <div className="wm">courses</div>

        <div className="container-fluid">
          <div className="row" id="courses">
            <div className="col-md-6 offset-md-3 text-center wow fadeInUp">
              <h5 className="s2">All About</h5>
              <h1>Courses</h1>
              <div className="separator">
                <span>
                  <i className="fa fa-square"></i>
                </span>
              </div>
              <div className="spacer-single"></div>
            </div>

            <div className="col-md-12">
              <div className="">
              {!isEmptyArray(coursesData) && ( 
                <Carousel
                  nextLabel={false}
                  prevLabel={false}
                  prevIcon={false}
                  nextIcon={false}
                  indicators={true}
                  indicatorLabels={[
                    <button role="button" className="owl-dot " ></button>,
                    <button role="button" className="owl-dot " ></button>
                  ]}
                  className="carousel-4-center-dots owl-carousel owl-theme owl-loaded owl-drag"
                >
                  {coursesData.map((ChunkData, key) => (

                  <Carousel.Item key={key}>
                    <div className="row">

                    {ChunkData.map((data, key_inner) => (

                      <div className="col-sm-3" key={key_inner}>
                        <div className="item text-middle text-light">
                          <div
                            style={{
                              background:
                                `url("${process.env.REACT_APP_IMAGE_URL+'/'+data.image}") center center / cover`,
                            }}
                          >
                            <div className="padding40 overlay60">
                              <h3>{data.title}</h3>
                              <p>
                              {data.description}
                              </p>
                              <a   className="btn-line btn-fullwidth cursor-pointer" onClick={() =>{   setCoursesDetails(data);  } } >
                                Read More
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                     

                      ))}  

                      </div>
                    </Carousel.Item>
                  
                  ))} 

                   
            

                </Carousel>
                )} 

              </div>
            </div>
          </div>
        </div>
      </section>


 



      {!isEmptyArray(coursesDetailsData) && ( 
        <section  style={{
                              background:
                                `url("${courseBackground}") 0% -73.675px / cover`,
                            }} >
          <div className="container">
            <div className="row">

              <div className="col-md-6 offset-md-3 text-center wow fadeInUp">
                <h5 className="s2">Discover</h5>
                <h1>Our Features</h1>
                <div className="separator">
                  <span><i className="fa fa-square"></i></span>
                </div>
                <div className="spacer-single"></div>
              </div>

              
              
              <div className="col-md-4"  >
                <div className="box-icon top">
                  <span className="icon wow bounceIn" data-wow-delay="0s"
                    ><i className="icon-paintbrush"></i></span>
                  <div className="text wow fadeIn" data-wow-delay=".15s">
                    <h3 className="style-1">Course syllabus</h3>
                    <p>
                    {(coursesDetailsData.course_syllabus) ? coursesDetailsData.course_syllabus : '-NA-'}
                    </p>
                  </div>
                </div>
              </div>


              <div className="col-md-4"  >
                <div className="box-icon top">
                  <span className="icon wow bounceIn" data-wow-delay="0s"
                    ><i className="icon-paintbrush"></i></span>
                  <div className="text wow fadeIn" data-wow-delay=".15s">
                    <h3 className="style-1">Course Duration</h3>
                    <p>
                     
                    {(coursesDetailsData.course_duration) ? coursesDetailsData.course_duration : '-NA-'}
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-md-4"  >
                <div className="box-icon top">
                  <span className="icon wow bounceIn" data-wow-delay="0s"
                    ><i className="icon-paintbrush"></i></span>
                  <div className="text wow fadeIn" data-wow-delay=".15s">
                    <h3 className="style-1">Course time</h3>
                    <p> 
                    {(coursesDetailsData.course_time_from) ? (coursesDetailsData.course_time_from+"-"+coursesDetailsData.course_time_to) : '-NA-'}
                    </p>
                  </div>
                </div>
              </div>

              <div className='spacer-single'></div>

              <div className="col-md-4"  >
                <div className="box-icon top">
                  <span className="icon wow bounceIn" data-wow-delay="0s"
                    ><i className="icon-paintbrush"></i></span>
                  <div className="text wow fadeIn" data-wow-delay=".15s">
                    <h3 className="style-1">Course Trainer</h3>
                    <p> 
                    {(coursesDetailsData.Trainer_list) ? coursesDetailsData.Trainer_list : '-NA-'}
                    </p>
                  </div>
                </div>
              </div>
                 

              <div className="col-md-4"  >
                <div className="box-icon top">
                  <span className="icon wow bounceIn" data-wow-delay="0s"
                    ><i className="icon-paintbrush"></i></span>
                  <div className="text wow fadeIn" data-wow-delay=".15s">
                    <h3 className="style-1">Course Price</h3>
                    <p> 
                    {(coursesDetailsData.course_price) ? coursesDetailsData.course_price : '-NA-'}
                    </p>
                  </div>
                </div>
              </div>


              <div className="col-md-4"  >
                <div className="box-icon top">
                  <span className="icon wow bounceIn" data-wow-delay="0s"
                    ><i className="icon-paintbrush"></i></span>
                  <div className="text wow fadeIn" data-wow-delay=".15s">
                    <h3 className="style-1">Accreditation</h3>
                    <p> 
                    {(coursesDetailsData.accreditation) ? coursesDetailsData.accreditation : '-NA-'}
                    </p>
                  </div>
                </div>
              </div>


              <div className="col-md-4"  >
                <div className="box-icon top">
                  <span className="icon wow bounceIn" data-wow-delay="0s"
                    ><i className="icon-paintbrush"></i></span>
                  <div className="text wow fadeIn" data-wow-delay=".15s">
                    <h3 className="style-1">Pre-requisite</h3>
                    <p> 
                    {(coursesDetailsData.pre_requisite) ? coursesDetailsData.pre_requisite : '-NA-'}
                    </p>
                  </div>
                </div>
              </div>


              <div className="col-md-4"  >
                <div className="box-icon top">
                  <span className="icon wow bounceIn" data-wow-delay="0s"
                    ><i className="icon-paintbrush"></i></span>
                  <div className="text wow fadeIn" data-wow-delay=".15s">
                    <h3 className="style-1">Equipment needed</h3>
                    <p>
                    
                    {(coursesDetailsData.equipment_needed) ? coursesDetailsData.equipment_needed : '-NA-'}
                    </p>
                  </div>
                </div>
              </div>


              


            </div>
          </div>
        </section>
        )}  

    </>
  )
}
export default OfflineCourses;