import React from 'react'

function HomeShop() {
  return (
    <>
    
    <div id="content">
  <div className="container">
	<h1 style={{color:'#fff'}}>SHOP</h1>
	<div className="row" style={{ justifyContent:'center', marginTop: '3rem'}}>
	  
	  
	  <ul className="products row">
		<li className="col-md-4 product">
		  <img src="https://accademy.impulsion.mu/html/assets/Logo/girl1.png" className="img-responsive" alt="" />
		  <h4>Shorts</h4>
		  <div className="price">$420</div>
		  <a href="cart.php" className="btn btn-line">Add To Cart</a>
		</li>

		<li className="col-md-4 product">
		  <img src="https://accademy.impulsion.mu/html/assets/Logo/girl2.png" className="img-responsive" alt="" />
		  <h4>Skirt</h4>
		  <div className="price">$210</div>
		  <a href="cart.php" className="btn btn-line">Add To Cart</a>
		</li>

		<li className="col-md-4 product">
		  <img src="https://accademy.impulsion.mu/html/assets/Logo/girl3.png" className="img-responsive" alt="" />
		  <h4>Dress</h4>
		  <div className="price">$180</div>
		  <a href="cart.php" className="btn btn-line">Add To Cart</a>
		</li>

		<li className="col-md-4 product">
		  <img src="https://accademy.impulsion.mu/html/assets/Logo/boy2.png" className="img-responsive" alt="" />
		  <h4>Pants</h4>
		  <div className="price">$240</div>
		  <a href="cart.php" className="btn btn-line">Add To Cart</a>
		</li>

		<li className="col-md-4 product">
		  <img src="https://accademy.impulsion.mu/html/assets/Logo/boy2.png" className="img-responsive" alt="" />
		  <h4>Joggers</h4>
		  <div className="price">$550</div>
		  <a href="cart.php" className="btn btn-line">Add To Cart</a>
		</li>

		<li className="col-md-4 product">
		  <img src="https://accademy.impulsion.mu/html/assets/Logo/boy2.png" className="img-responsive" alt="" />
		  <h4>T-Shirt</h4>
		  <div className="price">$120</div>
		  <a href="cart.php" className="btn btn-line">Add To Cart</a>
		</li>
	  </ul>
	  
	  
	</div>
  </div>
</div>

    </>
  )
}
export default HomeShop;