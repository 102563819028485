import React from 'react'
import { Link } from 'react-router-dom';
import AboutTeam from '../components/about/AboutTeam';
import Testimonials from '../components/home/Testimonials';

function About() {
  return (
    <>
    
    <div className="page-about">
        
        <section id="subheader"   style={{backgroundImage : 'https://accademy.impulsion.mu/html/assets/images/background/subheader-3.jpg'}} >
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <h1>About Us</h1>
                <ul className="crumb">
                  <li><Link to="/" >Home</Link></li>
                  <li className="sep">/</li>
                  <li>About Us</li>
                </ul>
              </div>
            </div>
          </div>
        </section> 


        <div id="content" className="no-top no-bottom">

          <section id="section-about-us-2" className="side-bg no-padding">
            <div className="image-container col-md-5 pull-left" data-delay="0" style={{backgroundImage: 'url(https://accademy.impulsion.mu/html/assets/Logo/about.jfif)'}}></div>

            <div className="container">
            <div className="row">
              <div className="inner-padding">
              <div
                className="col-md-6 offset-md-6"
                data-animation="fadeInRight"
                data-delay="200"
              >
                <h2>Award Winning Courses Design</h2>

                <p className="intro">
                Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                sed do eiusmod tempor incididunt ut labore et dolore magna
                aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                ullamco laboris nisi ut aliquip ex ea commodo consequat.
                </p>

                Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed
                do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                Ut enim ad minim veniam, quis nostrud exercitation ullamco
                laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                irure dolor in reprehenderit in voluptate velit esse cillum
                dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                cupidatat non proident, sunt in culpa qui officia deserunt
                mollit anim id est laborum.
              </div>
              <div className="clearfix"></div>
              </div>
            </div>
            </div>
          </section>

          <AboutTeam />
          <Testimonials />



        </div>
</div>	 



    </>
  )
}
export default About;