export const HOST = process.env.REACT_APP_API_URL;
export const IMAGE_URL = process.env.REACT_APP_IMAGE_URL;


export const Endpoint = { 
    
    HOME_SLIDER_LIST: HOST + "/home/sliders",
    HOME_ABOUT_DATA: HOST + "/home/about_us",
    TESTIMONIAL_LIST: HOST + "/testimonials",
    COURSE_CATEGORY: HOST + "/course/category",
    COURSE:  HOST + "/course",
    COURSE_FEATURED:  HOST + "/course_with_featured",

    ONLINE_COURSE_LIST:  HOST + "/online/course",    
    
    SUBMIT_CONTACT_FORM: HOST + "/contact/submit",    


    GALLERY_CATEGORY_LIST: HOST + "/gallery/category",
    GALLERY_LIST: HOST + "/gallery",
    TEAM_LIST: HOST + "/team",

    
    
}