import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { Client } from "../api/Client";
import { Endpoint } from "../Events/Endpoint";
import StringUtils from "../Utility/StringUtils";

function Contact() {

  const [successMessage, setSuccess] = useState(false);
    const [errMessage, setError] = useState('');

    const { register,handleSubmit,formState: { errors },} = useForm();

    const onSubmit = (inputJSON) => {
      console.log(inputJSON);

      let url = Endpoint.SUBMIT_CONTACT_FORM; 
        
         

      Client.postWithLoader(url, inputJSON, true, (response) => {
        
          console.log(response.data.status);

          if(StringUtils.isNotEmpty(response.data.status)){
            if(response.data.status ==  true){
              setSuccess(true);
            }else{
              setError(response.data.message);
              setTimeout(() => {
                setError("");
              }, 3000);

            }
          } 
        
        
        },
        (error) => {
            
        }
        ); 
    
    
    }

  return (
    <>
      <section
        id="subheader"
        style={{
          background:
            'url("https://accademy.impulsion.mu/html/assets/images/background/subheader.jpg") 0% 0px / cover',
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h1>Contact</h1>
              <ul className="crumb">
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li className="sep">/</li>
                <li>Contact</li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <div id="content" className="no-top">
        <div className="container">
          <div className="row">
            <div className="column">
              <div className="card">
                <div className="content">
                  <div className="front">
                    <img
                      className="profile"
                      width="100%"
                      src="https://accademy.impulsion.mu/html/assets/Logo/dance.webp"
                      alt="Neymar"
                    />
                    <h2>SHOP 1</h2>
                  </div>
                  <div className="back from-left">
                    <h2>Company</h2>
                    <h3>Address</h3>
                    <h3>Phone</h3>
                    <h3>Mobile</h3>

                    <br />
                    <p className="des">Email</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="column">
              <div className="card">
                <div className="content">
                  <div className="front">
                    <img
                      className="profile"
                      width="100%"
                      src="https://accademy.impulsion.mu/html/assets/Logo/dance.webp"
                      alt="Ronaldo"
                    />
                    <h2>SHOP 2</h2>
                  </div>
                  <div className="back from-bottom">
                    <h2>Company</h2>
                    <h3>Address</h3>
                    <h3>Phone</h3>
                    <h3>Mobile</h3>

                    <br />
                    <p className="des">Email</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="column">
              <div className="card">
                <div className="content">
                  <div className="front">
                    <img
                      className="profile"
                      width="100%"
                      src="https://accademy.impulsion.mu/html/assets/Logo/dance.webp"
                      alt="Messi"
                    />
                    <h2>SHOP 3</h2>
                  </div>
                  <div className="back from-right">
                    <h2>Company</h2>
                    <h3>Address</h3>
                    <h3>Phone</h3>
                    <h3>Mobile</h3>

                    <br />
                    <p className="des">Email</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container" style={{marginTop: '5rem'}} >
          <div className="row">
            <div className="col-md-8">
              <h3>Send Us Message</h3>

              <form
                name="contactForm"
                id="contact_form"
              
                onSubmit={handleSubmit(onSubmit)}
              >
                <div className="row">

                {(!successMessage) && (<>

                  <div className="col-md-4">
                    
                    <div className="mb-2">
                      <input
                        type="text"
                        name="name"
                        id="name"
                        className={"form-control "+((errors.Name) && 'error_input') }
                        placeholder="Your Name"
                        {...register("Name", { required: true, maxLength: 20 })}
                      />
                      {errors.Name && errors.Name.type === "required" && (
                      <span className="text-danger" role="alert">This is required</span>
                      )}
                      {errors.Name && errors.Name.type === "maxLength" && (
                          <span className="text-danger" role="alert">Max length exceeded</span>
                      )}  
                      
                      
                    </div>

                    
                    <div className="mb-2">
                      <input
                        type="text"
                        name="email"
                        id="email"
                        className={"form-control "+((errors.Email) && 'error_input') }
                        placeholder="Your Email"
                        {...register("Email",
                        {
                            required: true,
                            pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                        })}
                      />
                      {errors.Email && errors.Email.type === "required" && (
                      <span className="text-danger" role="alert">This is required</span>
                      )}
                      {errors.Email && errors.Email.type === "pattern" && (
                          <span className="text-danger" role="alert">Email id is not valid.</span>
                      )} 
                    </div>

                  
                    <div className="mb-2">
                      <input
                        type="text"
                        name="phone"
                        id="phone"
                        className={"form-control "+((errors.Phone) && 'error_input') }
                        placeholder="Your Phone"
                        {...register("Phone", { required: true, maxLength: 10, minLength: 10 })}
                      />
                      {errors.Phone && errors.Phone.type === "required" && (
                      <span className="text-danger" role="alert">This is required</span>
                      )}
                      {errors.Phone && errors.Phone.type === "minLength" && (
                          <span className="text-danger" role="alert">Phone number should be atleast 10 digit.</span>
                      )} 
                      {errors.Phone && errors.Phone.type === "maxLength" && (
                          <span className="text-danger" role="alert">Max length exceeded</span>
                      )} 
                    </div>
                  </div>
                  <div className="col-md-8">
                    
                    <div className="mb-2">
                      <textarea
                        name="message"
                        id="message"
                        className={"form-control "+((errors.Message) && 'error_input') }
                        placeholder="Your Message"
                        {...register("Message", { required: true, maxLength: 300, minLength: 30 })}
                      ></textarea>
                      {errors.Message && errors.Message.type === "required" && (
                        <span className="text-danger" role="alert">This is required</span>
                        )}
                        {errors.Message && errors.Message.type === "minLength" && (
                            <span className="text-danger" role="alert">Message should be atleast 30 character.</span>
                        )} 
                        {errors.Message && errors.Message.type === "maxLength" && (
                            <span className="text-danger" role="alert">Message should not be more than 300 character.</span>
                        )} 
                    </div>
                  </div>

                  <div className="col-md-12">
                    <p id="submit">
                      <input
                        type="submit"
                        id="send_message"
                        value="Submit Form"
                        className="btn btn-line"
                      />
                    </p> 
                  </div>
                  </>
                  )}



                  <div className="col-md-12">
                  {(successMessage) && (
                     <div id="mail_success" className="success">
                      Your message has been sent successfully.
                    </div>
                    )}

                  {(errMessage) && (
                    <div id="mail_fail" className="error">
                    {errMessage}
                  </div>
                  )}
                  </div>



                </div>
              </form>
            </div>

            <div id="sidebar" className="col-md-4">
              <div className="widget widget_text">
                <h3>Contact Info</h3>
                <address>
                  <span>100 Mainstreet Center, Sydney</span>
                  <span>
                    <strong>Company:</strong>&nbsp;&nbsp;IMPULSION
                  </span>
                  <span>
                    <strong>Phone:</strong>(208) 333 9296
                  </span>
                  <span>
                    <strong>Mobile</strong>(208) 333 9298
                  </span>
                  <span>
                    <strong>Email:</strong>
                    <a href="mailto:contact@example.com">contact@example.com</a>
                  </span>
                </address>
              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  );
}
export default Contact;
