import React from "react"; 
import OfflineCourses from "../components/courses/OfflineCourses";
import OnlineCourses from "../components/courses/OnlineCourses";

function Courses() {
  return (
    <>
      <section
        id="section-welcome-8"
        style={{
          background:
            'url("https://accademy.impulsion.mu/html/assets/images/background/bg-41.jpg") 0% 0px / cover',
        }}
        
      >
        <div className="spacer-single"></div>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-5">
              <h2>Learn in-demand professional skills</h2>
              <p className="lead">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat.
              </p>
              <div className="spacer-half"></div>
            </div>
            <div className="col-md-6 offset-md-1">
              <figure className="picframe invert transparent shadow-soft rounded">
                <span className="v-center">
                  <span>
                    <a
                      id="play-video"
                      className="video-play-button popup-youtube"
                      href="https://www.youtube.com/watch?v=CmCIZ_aUAeQ"
                    >
                      <span></span>
                    </a>
                  </span>
                </span>
                <img
                  src="https://accademy.impulsion.mu/html/assets/images/misc/pic_15.jpg"
                  className="img-fullwidth"
                  alt=""
                />
              </figure>
            </div>
          </div>
        </div>
      </section>

       <OfflineCourses/>

       <OnlineCourses />

    </>
  );
}
export default Courses;
