import React from 'react'
import { Link, useLocation } from 'react-router-dom';

function Shop() {
  let location = useLocation();

  return (
    <>

<div className={(location.pathname=='/shop' || location.pathname.includes('shop') ) && 'de_light' } > 
    
    <section
        id="subheader" 
        
         style={{
          background:
            'url("https://accademy.impulsion.mu/html/assets/images/background/bg-4.jpg") 0% 0px / cover',
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h1>Shop</h1>
              <ul className="crumb">
                <li><Link to="/" >Home</Link></li>
                <li className="sep">/</li>
                <li>Shop</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    


      <div id="content">
        <div className="container">
          <div className="row">
            <div className="col-md-9">
              <ul className="products row">
                <li className="col-md-4 product">
                  <img src="https://accademy.impulsion.mu/html/assets/images/shop/1.jpg" className="img-responsive" alt="" />
                  <h4>Woven Single Seat Sofa</h4>
                  <div className="price">$420</div>
                  <a href="cart.html" className="btn btn-line">Add To Cart</a>
                </li>

                <li className="col-md-4 product">
                  <img src="https://accademy.impulsion.mu/html/assets/images/shop/2.jpg" className="img-responsive" alt="" />
                  <h4>Classic Circular Table</h4>
                  <div className="price">$210</div>
                  <a href="cart.html" className="btn btn-line">Add To Cart</a>
                </li>

                <li className="col-md-4 product">
                  <img src="https://accademy.impulsion.mu/html/assets/images/shop/3.jpg" className="img-responsive" alt="" />
                  <h4>Woven Square Chair</h4>
                  <div className="price">$180</div>
                  <a href="cart.html" className="btn btn-line">Add To Cart</a>
                </li>

                <li className="col-md-4 product">
                  <img src="https://accademy.impulsion.mu/html/assets/images/shop/4.jpg" className="img-responsive" alt="" />
                  <h4>Multifunction Bed Red</h4>
                  <div className="price">$850</div>
                  <a href="cart.html" className="btn btn-line">Add To Cart</a>
                </li>

                <li className="col-md-4 product">
                  <img src="https://accademy.impulsion.mu/html/assets/images/shop/5.jpg" className="img-responsive" alt="" />
                  <h4>Minimalist Corner Desk</h4>
                  <div className="price">$240</div>
                  <a href="cart.html" className="btn btn-line">Add To Cart</a>
                </li>

                <li className="col-md-4 product">
                  <img src="https://accademy.impulsion.mu/html/assets/images/shop/6.jpg" className="img-responsive" alt="" />
                  <h4>Decorative Fabric Sofa</h4>
                  <div className="price">$550</div>
                  <a href="cart.html" className="btn btn-line">Add To Cart</a>
                </li>

                <li className="col-md-4 product">
                  <img src="https://accademy.impulsion.mu/html/assets/images/shop/7.jpg" className="img-responsive" alt="" />
                  <h4>Artistic Wood Hanger</h4>
                  <div className="price">$120</div>
                  <a href="cart.html" className="btn btn-line">Add To Cart</a>
                </li>

                <li className="col-md-4 product">
                  <img src="https://accademy.impulsion.mu/html/assets/images/shop/8.jpg" className="img-responsive" alt="" />
                  <h4>Classic Wood Chair</h4>
                  <div className="price">$170</div>
                  <a href="cart.html" className="btn btn-line">Add To Cart</a>
                </li>

                <li className="col-md-4 product">
                  <img src="https://accademy.impulsion.mu/html/assets/images/shop/9.jpg" className="img-responsive" alt="" />
                  <h4>White Blue Bed</h4>
                  <div className="price">$650</div>
                  <a href="cart.html" className="btn btn-line">Add To Cart</a>
                </li>

                <li className="col-md-4 product">
                  <img src="https://accademy.impulsion.mu/html/assets/images/shop/10.jpg" className="img-responsive" alt="" />
                  <h4>Woven Dinning Chair</h4>
                  <div className="price">$180</div>
                  <a href="cart.html" className="btn btn-line">Add To Cart</a>
                </li>

                <li className="col-md-4 product">
                  <img src="https://accademy.impulsion.mu/html/assets/images/shop/11.jpg" className="img-responsive" alt="" />
                  <h4>Classic Colorful Chair</h4>
                  <div className="price">$200</div>
                  <a href="cart.html" className="btn btn-line">Add To Cart</a>
                </li>

                <li className="col-md-4 product">
                  <img src="https://accademy.impulsion.mu/html/assets/images/shop/12.jpg" className="img-responsive" alt="" />
                  <h4>Rattan Triple Seat Sofa</h4>
                  <div className="price">$380</div>
                  <a href="cart.html" className="btn btn-line">Add To Cart</a>
                </li>
              </ul>
            </div>

            <div id="sidebar" className="col-md-3">
              <div className="widget widget_search">
                <input
                  type="text"
                  name="search"
                  id="search"
                  className="form-control"
                  placeholder="search product"
                />
                <button id="btn-search" type="submit"></button>
                <div className="clearfix"></div>
              </div>

              <div className="widget widget_top_rated_product">
                <h4>Top Rated Product</h4>
                <ul>
                  <li>
                    <img src="https://accademy.impulsion.mu/html/assets/images/shop/1.jpg" alt="" />
                    <div className="text">
                      Woven Single Seat Sofa
                      <div className="price">$420</div>
                    </div>
                  </li>
                  <li>
                    <img src="https://accademy.impulsion.mu/html/assets/images/shop/2.jpg" alt="" />
                    <div className="text">
                      Classic Circular Table
                      <div className="price">$210</div>
                    </div>
                  </li>
                  <li>
                    <img src="https://accademy.impulsion.mu/html/assets/images/shop/3.jpg" alt="" />
                    <div className="text">
                      Woven Square Chair
                      <div className="price">$180</div>
                    </div>
                  </li>
                  <li>
                    <img src="https://accademy.impulsion.mu/html/assets/images/shop/4.jpg" alt="" />
                    <div className="text">
                      Multifunction Bed Red
                      <div className="price">$850</div>
                    </div>
                  </li>
                  <li>
                    <img src="https://accademy.impulsion.mu/html/assets/images/shop/5.jpg" alt="" />
                    <div className="text">
                      Minimalist Corner Desk
                      <div className="price">$240</div>
                    </div>
                  </li>
                </ul>
              </div>

              <div className="widget widget_category">
                <h4>Product Category</h4>
                <ul>
                  <li><a href="#">Bed</a></li>
                  <li><a href="#">Cabinet</a></li>
                  <li><a href="#">Chair</a></li>
                  <li><a href="#">Desk</a></li>
                  <li><a href="#">Sofa</a></li>
                  <li><a href="#">Misc</a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      </div>
    </>
  )
}
export default Shop;