import React from "react";
import About from "../components/home/About";
import Courses from "../components/home/Courses";
import HomeOnlineCourses from "../components/home/HomeOnlineCourses";
import HomeShop from "../components/home/HomeShop";
import HomeSlider from "../components/home/HomeSlider";
import Testimonials from "../components/home/Testimonials";


function Home() {
  return (
    <>
      
      
      <HomeSlider />
      
      <About />

      <Courses />

      <HomeShop />

      <HomeOnlineCourses />

      <Testimonials />

      

    </>
  );
}
export default Home;
