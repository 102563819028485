
import logo from './../assets/Logo/Logo Impulsion (1)-5.jpg';
import React, {  useState } from 'react';
import Navigation from './Navigation';
import { Link, useLocation } from 'react-router-dom';



function Header() {

  let location = useLocation(); 
  
  return (
    <>
      <header className={(location.pathname=='/shop' || location.pathname.includes('shop') ) ? 'header-bg header-light clone smaller' : '' } > 
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              
              <div id="logo">
              <Link className="" to="/">
                  <img
                    className="logo"
                    src={logo}
                    height="100px"
                    width="140px"
                    alt=""
                  />
                </Link>
              </div>
              

              
              <span id="menu-btn"></span>
              

              
              <nav>
                <ul id="mainmenu" className="no-separator">

                  <Navigation />
                 
                </ul>
              </nav>
            </div>
            
          </div>
        </div>
      </header>


    </>
  )
}
export default Header;