import React from "react";

function HomeOnlineCourses() {
  return (
    <>
      <div id="content" className="no-top no-bottom">
        <h1 style={{ color: "#fff" }}>Online COURSES</h1>
        <section id="pricing-table" style={{ backgroundColor: "#111" }}>
          <div className="item pricing">
            <div className="container">
              <div className="row">
                <div className="col-sm-4">
                  <div className="pricing-s1 light mb30">
                    <div className="top">
                      <h2>Intro to Gymnastics</h2>
                      <p className="price">
                        <span className="currency">$</span> <b>99</b>
                        <span className="month">p/annual</span>
                      </p>
                    </div>
                    <div className="bottom">
                      <p className="box-bg-1">
                        Sed ut perspiciatis unde omnis iste natus error sit
                        voluptatem accusantium doloremque laudantium, totam rem
                        aperiam, eaque ipsa quae ab illo inventore veritatis et
                        quasi architecto beatae vitae dicta sunt explicabo. Nemo
                        enim ipsam voluptatem quia voluptas sit aspernatur aut
                        odit aut fugit, sed quia consequuntur magni dolores eos
                        qui ratione voluptatem sequi nesciunt.
                      </p>

                      <div className="container">
                        <a href="index-modern.php">
                         
                          <button type="button" className="btn  btn-lg">
                            view details
                          </button>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="pricing-s1 light mb30">
                    <div className="top">
                      <h2>Basic Intermediate</h2>
                      <p className="price">
                        <span className="currency">$</span> <b>299</b>
                        <span className="month">p/annual</span>
                      </p>
                    </div>
                    <div className="bottom">
                      <p className="box-bg-1">
                        Sed ut perspiciatis unde omnis iste natus error sit
                        voluptatem accusantium doloremque laudantium, totam rem
                        aperiam, eaque ipsa quae ab illo inventore veritatis et
                        quasi architecto beatae vitae dicta sunt explicabo. Nemo
                        enim ipsam voluptatem quia voluptas sit aspernatur aut
                        odit aut fugit, sed quia consequuntur magni dolores eos
                        qui ratione voluptatem sequi nesciunt.
                      </p>

                      <div className="container">
                        <a href="index-modern.php">
                          
                          <button type="button" className="btn  btn-lg">
                            view details
                          </button>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="pricing-s1 light mb30">
                    <div className="top">
                      <h2>Ninja Parkour</h2>
                      <p className="price">
                        <span className="currency">$</span> <b>499</b>
                        <span className="month">p/annual</span>
                      </p>
                    </div>
                    <div className="bottom">
                      <p className="box-bg-1">
                        Sed ut perspiciatis unde omnis iste natus error sit
                        voluptatem accusantium doloremque laudantium, totam rem
                        aperiam, eaque ipsa quae ab illo inventore veritatis et
                        quasi architecto beatae vitae dicta sunt explicabo. Nemo
                        enim ipsam voluptatem quia voluptas sit aspernatur aut
                        odit aut fugit, sed quia consequuntur magni dolores eos
                        qui ratione voluptatem sequi nesciunt.
                      </p>
                      <div className="container">
                        <a href="index-modern.php">
                          
                          <button type="button" className="btn  btn-lg">
                            view details
                          </button>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default HomeOnlineCourses;
