import Isotope from "isotope-layout";
import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { Client } from "../../api/Client";
import { Endpoint } from "../../Events/Endpoint";
import { isEmptyArray } from "../../Utility/Utility";

function Courses() {

    const [categoryData, setCategory] = useState([]);
    const [coursesData, setCourses] = useState([]);

    const gridRef = useRef(null);
    const isotope = useRef();
    const [filterKey, setFilterKey] =useState('*');

    /* useEffect(() => {

      isotope.current = new Isotope(gridRef.current, {
        itemSelector: '.element-item',
        layoutMode: 'fitRows',
      });      

        setTimeout(() => { 
        // isotope.current.arrange({filter: `*`});
        }, 800); 
       

      return () => isotope.current.destroy();  
      
    }, [])  */

    useEffect(() => {

      getCategory(); 
      getCourse(); 

      setIsotop();

      setTimeout(() => { 
        setIsotop();
      }, 2800);  
       
   }, []) 
     
    useEffect(() => {  

      filterKey === '*'
        ? isotope.current.arrange({filter: `*`})
        : isotope.current.arrange({filter: `${filterKey}`}); 

       console.log("filterKey= ",filterKey);



    }, [filterKey])
    


    function setIsotop() {

      isotope.current = new Isotope(gridRef.current, {
        itemSelector: '.element-item',
        layoutMode: 'fitRows',
      });     

      return () => isotope.current.destroy();  
    }

    

    function getCategory() {
      const url = Endpoint.COURSE_CATEGORY;;
      Client.getWithLoader(
        url,
        (response) => {
          /* console.log("response category", response.data.data); */
          if (!isEmptyArray(response.data)) {
            if (response.data.status) {
              setCategory(response.data.data);
            }
          }
        },
        (error) => {
          setCategory([]);
        }
      );
    }

    function getCourse() {
      const url = Endpoint.COURSE;;
      Client.getWithLoader(
        url,
        (response) => {
          /* console.log("response course", response.data.data); */
          if (!isEmptyArray(response.data)) {
            if (response.data.status) {
              setCourses(response.data.data);
              setIsotop();
            }
          }
        },
        (error) => {
          setCourses([]);
        }
      );
    }
  
    const handleFilterKeyChange = key => () => setFilterKey(key);


  return (
    <>

    
<section
          id="section-portfolio"
          className="no-top no-bottom"
          aria-label="section-portfolio"
        >
          <div className="container">
            <div className="spacer-single"></div>

            <div className="row">
              <div className="col-md-12 text-center">
                <h1 style={{color: '#fff'}} >COURSES</h1>

                <ul
                  id="filters"
                  className="wow fadeInUp" 
                  style={{marginTop: '3rem'}}
                >
                  <li><a className={(filterKey=='*') ? 'selected cursor-pointer': 'cursor-pointer'} onClick={handleFilterKeyChange('*')} >All Courses</a></li>

                  {/* <li><a className={(filterKey=='.residential') ? 'selected' : ''}   onClick={handleFilterKeyChange('.residential')} >Gymnastics</a></li>
                  <li><a className={(filterKey=='.hospitaly') ? 'selected' : ''} onClick={handleFilterKeyChange('.hospitaly')}  >Parkour</a></li>
                  <li><a className={(filterKey=='.office') ? 'selected' : ''} onClick={handleFilterKeyChange('.office')} >Capoeira</a></li>
                  <li><a className={(filterKey=='.commercial') ? 'selected' : ''} onClick={handleFilterKeyChange('.commercial')}  >Tricking</a></li>
                   */}

                  {!isEmptyArray(categoryData) &&
                  categoryData.map((data, key) => (

                    <li key={key}>
                      <a className={(filterKey==`.${data.slug}`) ? 'selected cursor-pointer' : 'cursor-pointer'} onClick={handleFilterKeyChange(`.${data.slug}`)}  >
                        {data.category_title}
                       </a> 
                    </li>
                    
                    ))}

                </ul>

                </div>
            </div> 
          </div>



        <div  ref={gridRef}
          id="gallery"
          className="gallery full-gallery de-gallery pf_full_width wow fadeInUp" 
          style={{height: '600px'}} 
        >

          {!isEmptyArray(categoryData) && ( 
            coursesData.map((data, key) => (

              <div key={key} className={`element-item item ${data.category_slug}`}>
              <div className="picframe">
              <Link to="/courses">
                  <span className="overlay">
                    <span className="pf_text">
                      <span className="project-name"> {data.title}</span>
                    </span>
                  </span>
                </Link>
                <img
                      src={process.env.REACT_APP_IMAGE_URL + "/" + data.image}
                      alt=""
                    />
              </div>
            </div>

            )) 
            )} 

        </div>
    

    <div id="loader-area">
      <div className="project-load"></div>
    </div>
</section>


 <section
          id="view-all-projects"
          className="call-to-action bg-color text-center"
          data-speed="5"
          data-type="background"
          aria-label="view-all-projects"
        >
        <Link to="/courses" className="btn btn-line black btn-big" >View All Courses </Link>             
              
          
        </section>

    </>
  );
}
export default Courses;
