import React from "react";
import { Link } from "react-router-dom";
import GalleryListing from "../components/gallery/GalleryListing";

function Gallery() {
  return (
    <>
      <div className="page-projects">

        <section
          id="subheader"
          style={{
            background:
              'url("https://accademy.impulsion.mu/html/assets/images/background/subheader-1.jpg") 0% 0px / cover',
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <h1>Gallery</h1>
                <ul className="crumb">
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li className="sep">/</li>
                  <li>Gallery</li>
                </ul>
              </div>
            </div>
          </div>
        </section>


            
           
<section
  id="section-portfolio"
  className="no-top no-bottom"
  aria-label="section-portfolio"
>
 

	 <GalleryListing />
 
 
 
</section>    


      </div>
    </>
  );
}
export default Gallery;
