import React from 'react'

 function AboutTeam() {
  return (
    <>

            <section id="section-team">
                <div className="container">
                <div className="row">
                    <div className="col-md-12 container-4">
                    
                        
                    <div className="de-team-list">
                        <div className="team-pic">
                        <img
                            src="https://accademy.impulsion.mu/images/team/team_pic_1.jpg"
                            className="img-responsive"
                            alt=""
                        />
                        </div>
                        <div className="team-desc col-md-12">
                        <h3>John Smith</h3>
                        <p className="lead">Project Manager</p>
                        <div className="small-border"></div>
                        <p>
                            Duis aute irure dolor in reprehenderit in voluptate velit
                            esse cillum dolore eu fugiat nulla pariatur.
                        </p>

                        <div className="social">
                            <a href="#"><i className="fa fa-facebook fa-lg"></i></a>
                            <a href="#"><i className="fa fa-twitter fa-lg"></i></a>
                            <a href="#"><i className="fa fa-google-plus fa-lg"></i></a>
                            <a href="#"><i className="fa fa-skype fa-lg"></i></a>
                        </div>
                        </div>
                    </div>
                        

                        
                    <div className="de-team-list">
                        <div className="team-pic">
                        <img
                            src="https://accademy.impulsion.mu/images/team/team_pic_2.jpg"
                            className="img-responsive"
                            alt=""
                        />
                        </div>
                        <div className="team-desc col-md-12">
                        <h3>Michael Dennis</h3>
                        <p className="lead">Creative Director</p>
                        <div className="small-border"></div>
                        <p>
                            Duis aute irure dolor in reprehenderit in voluptate velit
                            esse cillum dolore eu fugiat nulla pariatur.
                        </p>

                        <div className="social">
                            <a href="#"><i className="fa fa-facebook fa-lg"></i></a>
                            <a href="#"><i className="fa fa-twitter fa-lg"></i></a>
                            <a href="#"><i className="fa fa-google-plus fa-lg"></i></a>
                            <a href="#"><i className="fa fa-skype fa-lg"></i></a>
                        </div>
                        </div>
                    </div>
                    

                    
                    <div className="de-team-list">
                        <div className="team-pic">
                        <img
                            src="https://accademy.impulsion.mu/images/team/team_pic_3.jpg"
                            className="img-responsive"
                            alt=""
                        />
                        </div>
                        <div className="team-desc col-md-12">
                        <h3>Sarah Michelle</h3>
                        <p className="lead">Creative Staff</p>
                        <div className="small-border"></div>
                        <p>
                            Duis aute irure dolor in reprehenderit in voluptate velit
                            esse cillum dolore eu fugiat nulla pariatur.
                        </p>
                        <div className="social">
                            <a href="#"><i className="fa fa-facebook fa-lg"></i></a>
                            <a href="#"><i className="fa fa-twitter fa-lg"></i></a>
                            <a href="#"><i className="fa fa-google-plus fa-lg"></i></a>
                            <a href="#"><i className="fa fa-skype fa-lg"></i></a>
                        </div>
                        </div>
                    </div>
                        

                    
                    <div className="de-team-list">
                        <div className="team-pic">
                        <img
                            src="https://accademy.impulsion.mu/images/team/team_pic_4.jpg"
                            className="img-responsive"
                            alt=""
                        />
                        </div>
                        <div className="team-desc col-md-12">
                        <h3>Katty Wilson</h3>
                        <p className="lead">Creative Staff</p>
                        <div className="small-border"></div>
                        <p>
                            Duis aute irure dolor in reprehenderit in voluptate velit
                            esse cillum dolore eu fugiat nulla pariatur.
                        </p>
                        <div className="social">
                            <a href="#"><i className="fa fa-facebook fa-lg"></i></a>
                            <a href="#"><i className="fa fa-twitter fa-lg"></i></a>
                            <a href="#"><i className="fa fa-google-plus fa-lg"></i></a>
                            <a href="#"><i className="fa fa-skype fa-lg"></i></a>
                        </div>
                        </div>
                    </div>
                        

                    <div className="clearfix"></div>
                    </div>
                </div>
                </div>
            </section>
    
    </>
  )
}
export default AboutTeam;
