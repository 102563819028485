import React, { useEffect, useState } from 'react'
import { Client } from '../../api/Client';
import { Endpoint } from '../../Events/Endpoint';
import { isEmptyArray } from '../../Utility/Utility';

function OnlineCourses() {
  const [coursesData, setCourses] = useState([]);
  const [coursesDetailsData, setCoursesDetails] = useState([]);

  useEffect(() => {
    getCourse();  
  }, []); 

  function getCourse() {
    const url = Endpoint.ONLINE_COURSE_LIST;
    Client.getWithLoader(
      url,
      (response) => {
         
        if (!isEmptyArray(response.data)) {
          if (response.data.status) { 
            setCourses(response.data.data);
 
            (!isEmptyArray(response.data.data[0]) &&  setCoursesDetails(response.data.data[0].id) );
             
          }
        }
      },
      (error) => {
        setCourses([]);
      }
    );
  }


  return (
    <>
    
    <section className="de_light" style={{backgroundColor: '#ffffff'}}>
          <div className="wm">Courses</div>

          <div className="container">
            <div className="row">
              <div className="col-md-6 offset-md-3 text-center wow fadeInUp">
                <h5 className="s2">How It Works</h5>
                <h1>Online Courses</h1>
                <div className="separator">
                  <span><i className="fa fa-square"></i></span>
                </div>
                <div className="spacer-single"></div>
              </div>

              <div className="col-md-12">
                <div className="de_tab tab_steps square dark">
                  <ul className="de_nav">

                  {!isEmptyArray(coursesData) &&
                  coursesData.map((data, key) => ( 


                    <li key={key}  className={"wow fadeIn "+((data.id==coursesDetailsData) ? 'animated active' : '')} data-wow-delay="0s"  onClick={() =>{   setCoursesDetails(data.id);  } } >
                      <span>{data.title}</span>
                      <div className="v-border"></div>
                    </li>

                     ))}  
                  </ul>


                  {!isEmptyArray(coursesDetailsData) && (    
                  <div className="de_tab_content">


                      { coursesData.map((data, key) => (

                        <div key={key} className={((data.id==coursesDetailsData) ? 'active' : 'hideCourse')} >
                                
                                {data.description}

                                <div className="row">
                                  <div className="col-md-12">  <h4> Demo video </h4> </div>
                                </div> 

                                <div className="row">
                                {data.short_videos.map((video, key) => (
                                <div key={key} className="col-md-4"> 
                                    <video playsInline="" autoplay="" muted="" loop="" width="100%" height="100%" src={process.env.REACT_APP_VIDEO_URL + "/" + video} type="video/mp4" />
                                </div>

                                ))}  

                                </div>
                          </div> 
                      ))}


                    


                  </div>
                  )}  



                </div>
              </div>
			   
			  
			  
            </div>
          </div>
        </section>

    
    </>
  )
}
export default OnlineCourses;