import React from "react";

export default function Footer() {
  return (
    <>
      <footer>
        <div className="container">
          <div className="row">
            <div className="col-md-3">
              <div className="widget widget_recent_post">
                <h3>Shop</h3>
                <ul>
                  <li>
                    <a href="#">Men</a>
                  </li>
                  <li>
                    <a href="#">Women</a>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-md-3">
              <div className="widget widget_recent_post">
                <h3>Help</h3>
                <ul>
                  <li>
                    <a href="#">Shipping Policy</a>
                  </li>
                  <li>
                    <a href="#">Privacy Policy</a>
                  </li>
                  <li>
                    <a href="#">Terms & Conditions</a>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-md-3">
              <div className="widget widget_recent_post">
                <h3 >Latest News</h3>
                <ul>
                  <li>
                    <a href="blog-single.html">
                      5 Things That Take a Room from Good to Great
                    </a>
                  </li>
                  <li>
                    <a href="blog-single.html">
                      Functional and Stylish Wall-to-Wall Shelves
                    </a>
                  </li>
                  <li>
                    <a href="blog-single.html">
                      9 Unique and Unusual Ways to Display Your TV
                    </a>
                  </li>
                  <li>
                    <a href="blog-single.html">
                      The 5 Secrets to Pulling Off Simple, Minimal Design
                    </a>
                  </li>
                  <li>
                    <a href="blog-single.html">
                      How to Make a Huge Impact With Multiples
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-md-3">
              <h3>Contact Us</h3>
              <div className="widget widget-address">
                <address>
                  <span>100 Mainstreet Center, Sydney</span>
                  <span>
                    <strong>Phone:</strong>(208) 333 9296
                  </span>
                  <span>
                    <strong>Fax:</strong>(208) 333 9298
                  </span>
                  <span>
                    <strong>Email:</strong>
                    <a href="mailto:contact@archi-interior.com">
                      contact@archi-interior.com
                    </a>
                  </span>
                  <span>
                    <strong>Web:</strong>
                    <a href="#"></a>
                  </span>
                </address>
              </div>
            </div>
          </div>
        </div>

        <div className="subfooter">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                © Copyright 2023 -{" "}
                <span style={{color:'#FAB702'}} >Impulsion Team</span> Designed by
                High End Graphics Ltd
              </div>
              <div className="col-md-6 text-right">
                <div className="social-icons">
                  <a href="#">
                    <i className="fa fa-facebook fa-lg"></i>
                  </a>
                  <a href="#">
                    <i className="fa fa-twitter fa-lg"></i>
                  </a>
                  <a href="#">
                    <i className="fa fa-rss fa-lg"></i>
                  </a>
                  <a href="#">
                    <i className="fa fa-google-plus fa-lg"></i>
                  </a>
                  <a href="#">
                    <i className="fa fa-skype fa-lg"></i>
                  </a>
                  <a href="#">
                    <i className="fa fa-dribbble fa-lg"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <a href="#" id="back-to-top"></a>
      </footer>
    </>
  );
}
